import React from "react"
import scrollTo from "gatsby-plugin-smoothscroll"
import logoNavBar from "../images/logo_nav_bar.jpeg"



const Navbar = () => {

  const logo = logoNavBar
  
  return (
    <div className="section">
      <div className="container">
        <div className="navbar-wrapper" >
            <div className="navbar-logo">
            <img src={logo} alt="css"></img>
            </div>      
            {/* <div className="navbar-menu-wrapper">
              
              <div
                role="button"
                onClick={() => scrollTo("#home")}
                className="name"
                tabIndex={0}
              >
                
              </div>
              <div className="links-wrapper">
                <button onClick={() => scrollTo("#work")}>Episódios</button>
                <button onClick={() => scrollTo("#skills")}>Quem</button>
                <button onClick={() => scrollTo("#contact")}>Contato</button>
              </div>
            </div> */}
        </div>


      </div>
    </div>
  )
}

export default Navbar
